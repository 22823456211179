<template>
  <div class="schedule-container">
    <img :src="meetingInfo.profile?.file_regular_pic_invitation" alt="" v-if="meetingInfo.profile?.file_regular_pic_invitation" @load="initScroll()">
  </div>
</template>
<script>
export default {
  name:"schedule",
  props:{
    meetingInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  methods:{
    initScroll(){
      this.$emit("initScroll",true)
    }
  }
}
</script>
<style>
.schedule-container img{
  width:100%;
  height:auto;
}</style>